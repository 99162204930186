






























import './styles/WorkConstructs.scss';
import './styles/WorkConstructsAdaptive.scss';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IConstruct } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions, WorkListMutations } from '@store/modules/work-list/Types';
import { ISearchResult } from '@store/modules/project/Interfaces';
import { ProjectActions } from '@store/modules/project/Types';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');

@Component({
    name: 'WorkConstructs',

    components: {
        BaseListItemShell: () => import('@components/BaseListItemShell/BaseListItemShell.vue'),
        ConstructRow: () => import('./ConstructRow.vue'),
        ActionsOnSelectedConstructs: () => import('./ActionsOnSelectedConstructs.vue'),
    },
})
export default class WorkList extends Vue {
    @Prop({}) constructsToDisplay!: any[];
    @Prop({}) titleToDisplay!: any[];

    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSProject.Getter('searchResult') searchResult!: ISearchResult;
    @NSProject.Action(ProjectActions.A_SET_SEARCH_RESULT) setSearchResult!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_SET_SEARCHING_PROCESS) setSearchingProcess!: (any) => Promise<void>;
    @NSWorkList.Action(WorkListActions.A_SET_SEARCH_RESULTS_IDS_CHAIN) setSearchResultsIdsChain!: (payload) => void;
    @NSWorkList.Mutation(WorkListMutations.M_TOGGLE_CONSTRUCT_SELECTION)
    toggleConstructSelection!: (construct: IConstruct) => void;

    timeForFullAnimationColored: number = 6000;

    @Watch('searchResult')
    onChangeSearchResult() {
        this.checkSearchResults();
    }

    mounted() {
        this.checkSearchResults();
    }

    emitConstructActionFired(actionInfo: { action: string, target: IConstruct }) {
        this.$emit('construct-action-fired', actionInfo);
    }

    checkConstructiveSearchResult(item) {
        if (this.searchResult && this.searchResult.group && this.searchResult.group === 'Конструктивы') {
            return this.searchResult && this.searchResult.item && item.uuid === this.searchResult.item.id;
        }
        return false;
    }

    checkSearchResults () {
        if (this.searchResult && this.searchResult.group && this.searchResult.group === 'Конструктивы') {
            setTimeout(() => {
                let coloredLi = document.querySelector('.js--colored');
                if (coloredLi) {
                    window.scrollTo(0, coloredLi.getBoundingClientRect().top - 300);
                    setTimeout(() => {
                        this.setSearchResult({});
                        // this.setSearchResultsIdsChain([]);
                    }, this.timeForFullAnimationColored);
                }
                this.setSearchingProcess(false);
            }, 500);
        }
    }
}
